
import { defineComponent, ref } from "vue";
import {
  IonPage,
  IonContent,
  IonText,
  IonButton,
  IonBadge,
  IonRippleEffect,
  IonIcon,
  IonSelect,
  IonSelectOption,
  modalController,
  toastController,
} from "@ionic/vue";
import PageHeader from "@/components/partial/PageHeader.vue";
import OrderCard from "@/components/functional/order/OrderCard.vue";
import { create } from "ionicons/icons";
import AdjustItem from "@/components/partial/AdjustItem.vue";
import LocationOrder from "@/components/partial/LocationOrder.vue";
import axios from "axios";

export default defineComponent({
  name: "OrderDetail",
  components: {
    IonPage,
    IonContent,
    IonText,
    IonButton,
    IonBadge,
    IonRippleEffect,
    IonIcon,
    PageHeader,
    OrderCard,
    IonSelect,
    IonSelectOption,
  },

  setup() {
    return { create };
  },

  data() {
    return {
      sheetState: null as any,
      orderStatus: "",
      info: {} as any,
      menus: [] as any,
      paymentMethods: [] as any,
      selectedPaymentMethod: null,
    };
  },

  watch: {
    selectedPaymentMethod(val) {
      console.log(val);
    },
  },

  methods: {
    async bayar() {
      if (this.info.alamat == "" || this.info.alamat == null) {
        const toast = await toastController.create({
          message: "Silakan isi alamat terlebih dahulu",
          duration: 1500,
        });
        return toast.present();
      } else if (this.selectedPaymentMethod == null) {
        const toast = await toastController.create({
          message: "Silakan Pilih Metode Pembayaran",
          duration: 1500,
        });
        return toast.present();
      }

      return this.$router.push(
        `/order/checkout/${this.$route.params.id}:${
          this.paymentMethods[this.selectedPaymentMethod].id_payment
        }/process`
      );
    },
    async getPaymentMethod() {
      await axios.get("payment-method").then(({ data }) => {
        this.paymentMethods = data.data;
      });
    },
    async openModalAddress() {
      if (this.orderStatus != "Unpaid") return;
      const modal = await modalController.create({
        component: LocationOrder,
        componentProps: {
          info: {
            no_transksi: this.$route.params.id,
            alamat: this.info.alamat,
            nama: this.info.nama,
            hp: this.info.hp,
            lat: this.info.latitude,
            lng: this.info.longitude,
            prov: this.info.prov,
            kota: this.info.kota,
            kec: this.info.kec
          },
        },
        swipeToClose: true,
      });
      modal.present();
      modal.onDidDismiss().then(() => this.getUnpaidTrx());
    },
    async openModal(menu: any) {
      if (this.orderStatus != "Unpaid") return;
      const modal = await modalController.create({
        component: AdjustItem,
        componentProps: {
          menu: menu,
        },
        swipeToClose: true,
      });
      modal.present();
      modal.onDidDismiss().then(() => this.getUnpaidTrx());
    },
    async getUnpaidTrx() {
      const formData = new FormData();
      this.$route.params.id &&
        formData.append("trx", this.$route.params.id.toString());
      await axios.post("transaction-details", formData).then(({ data }) => {
        this.info = data.data;
        this.orderStatus = data.data.status_transaksi;
        this.menus = data.detail;
      });
    },
  },
  mounted() {
    this.getUnpaidTrx();
    this.getPaymentMethod();
  },
});


import { defineComponent } from "vue";
import { IonText, IonIcon, IonButton, modalController } from "@ionic/vue";
import { create } from "ionicons/icons";
import Review from "@/views/order/Review.vue";
import CancelOrder from "@/views/order/CancelOrder.vue";
export default defineComponent({
  name: "OrderCard",

  // components: { IonText, IonIcon, IonButton },

  setup() {
    return { create };
  },

  methods: {
    async openModalRetur(menu: any) {
      const modal = await modalController.create({
        component: CancelOrder,
        componentProps: {
          menu: menu,
        },
      });
      modal.present();
      modal.onDidDismiss().then(() => this.$emit("updatedRating"));
    },
    async openModalReview(menu: any) {
      const modal = await modalController.create({
        component: Review,
        componentProps: {
          menu: menu,
        },
      });
      modal.present();
      modal.onDidDismiss().then(() => this.$emit("updatedRating"));
    },
  },

  props: {
    menu: {
      type: Object,
      default: null,
    },
    info: {
      type: Object,
      default: null,
    },
    noEdit: {
      type: Boolean,
      default: false,
    },
    grayscale: {
      type: Boolean,
      default: false,
    },
  },
});

<template>
  <ion-content :fullscreen="true">
    <div
      class="w-full bg-light bg-cover bg-no-repeat bg-center"
      style="height: 40vh"
      :style="{ backgroundImage: 'url(' + menu.gambar + ')' }"
    />
    <div
      class="px-2 pt-8 flex ion-padding items-center"
      style="justify-content: space-between"
    >
      <ion-text
        class="font-bold"
        style="font-size: 24px; text-transform: capitalize"
      >
        {{ menu.menu }}
      </ion-text>
      <ion-text class="font-bold" style="font-size: 20px">
        {{ $filters.idr(menu.price) }}
      </ion-text>
    </div>
    <div class="px-2">
      <ion-text>
        {{ menu.ket }}
      </ion-text>
    </div>

    <div style="position: fixed; bottom: 0; right: 0; left: 0; width: 100%">
      <div class="w-full flex justify-center">
        <div class="counter mb-4">
          <div class="btn min cursor-pointer" @click="minPortion">-</div>
          <div class="mx-4" style="font-size: 24px">{{ portionCount }}</div>
          <div class="btn plus cursor-pointer" @click="portionCount++">+</div>
        </div>
      </div>
      <ion-button
        :disabled="loading"
        @click="updateItem"
        expand="full"
        size="large"
        :color="jumlah == 0 ? 'danger' : 'warning'"
      >
        <ion-text>
            {{ jumlah == 0 ? 'Hapus Item' : 'Perbarui Item - '+$filters.idr(jumlah) }}
            
            </ion-text> 
      </ion-button>
    </div>
  </ion-content>
</template>

<script>
import {
  IonContent,
  IonText,
  modalController,
  toastController,
} from "@ionic/vue";
import axios from "axios";
import { defineComponent } from "vue";

export default defineComponent({
  name: "AdjustItem",
  props: {
    menu: { type: Object, default: null },
  },
  watch: {
    portionCount(val) {
      this.jumlah = this.menu.price * val;
    },
  },
  mounted() {
    this.portionCount = this.menu.quantity;
  },
  data() {
    return {
      content: "Content",
      portionCount: 0,
      jumlah: 0,
      loading: false,
    };
  },
  methods: {
    minPortion() {
      if (this.portionCount > 0) {
        this.portionCount--;
      }
    },
    async updateItem() {
      const formData = new FormData();
      formData.append("id_menu", this.menu.id);
      formData.append("qty", this.portionCount);
      formData.append("trx", this.menu.id_trx);
      this.loading = true;
      const url = (this.jumlah > 0 ? 'qty-update' : 'menu-delete')
      await axios
        .post(url, formData)
        .then(async ({ data }) => {
          this.loading = false;
          await modalController.dismiss();
        })
        .catch(async ({ response }) => {
          this.loading = false;
          const toast = await toastController.create({
            message: response.data.message,
            duration: 1500,
          });
          toast.present();
        });
    },
  },
  components: { IonContent, IonText },
});
</script>
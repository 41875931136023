
import { defineComponent } from "vue";
import {
  IonPage,
  IonContent,
  IonText,
  IonButton,
  IonItem,
  IonLabel,
  IonTextarea,
  modalController,
  toastController,
} from "@ionic/vue";
import axios from "axios";

export default defineComponent({
  name: "CancelOrder",

  components: {
    IonPage,
    IonContent,
    IonText,
    IonButton,
    IonItem,
    IonLabel,
    IonTextarea,
  },

  methods: {
    async returOrder() {
      const formdata = new FormData();
      this.alasan && formdata.append("alasan", this.alasan);
      formdata.append("trx", this.menu.id_trx);
      formdata.append("id_menu", this.menu.id_menu);
      formdata.append("qty", (this.portionCount-1).toString());
      this.loading = true;
      await axios
        .post("retur", formdata)
        .then(async ({ data }) => {
          const toast = await toastController.create({
            message: data.message,
            duration: 1500,
          });
          toast.present();
          await modalController.dismiss();
          this.loading = false;
        })
        .catch(async ({ response }) => {
          const toast = await toastController.create({
            message: response.data.message,
            duration: 1500,
          });
          toast.present();
          await modalController.dismiss();
          this.loading = false;
        });
    },
    async closeModal() {
      await modalController.dismiss();
    },
  },
  mounted() {
    this.portionCount = this.menu.quantity
  },
  props: {
    menu: {} as any,
  },
  data() {
    return {
      alasan: null,
      loading: false,
      portionCount: 0
    };
  },
});
